import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import BhajanDetails from './BhajanDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHome, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp } from '@fortawesome/free-solid-svg-icons';
import NotFound from './NotFound';

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [bhajanList, setBhajanList] = useState([]);
  const [filteredBhajans, setFilteredBhajans] = useState([]);
  const [selectedBhajan, setSelectedBhajan] = useState(null);
  const [headerBackground, setHeaderBackground] = useState('var(--app-background-color)');
  const [sortType, setSortType] = useState('index');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedBhajans, setSortedBhajans] = useState([]);

  const handleSortClick = (type) => {
    if (type === sortType) {

      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortType(type);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    const filtered = bhajanList.filter(item =>
      item.bhajanEng.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      item.bhajanHin.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      item.bhajanIAST.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      item.index.toString().includes(searchTerm.trim())
    );

    setFilteredBhajans(filtered);
  }, [searchTerm, bhajanList]);

  useEffect(() => {
    fetch('/bhajans.json')
      .then(response => response.json())
      .then(data => {
        setBhajanList(data);
        setFilteredBhajans(data);
      })
      .catch(error => console.error('Error loading bhajans:', error));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newHeaderBackground = scrollY > 50 ? '#38474E' : 'var(--app-background-color)';
      setHeaderBackground(newHeaderBackground);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const sortedList = [...filteredBhajans].sort((a, b) => {
      if (sortType === 'index') {
        return sortDirection === 'asc' ? a.index - b.index : b.index - a.index;
      } else if (sortType === 'alphabetical') {
        const bhajanA = a.bhajanHin.toLowerCase();
        const bhajanB = b.bhajanHin.toLowerCase();
        return sortDirection === 'asc' ? bhajanA.localeCompare(bhajanB) : bhajanB.localeCompare(bhajanA);
      }
      return 0;
    });

    setSortedBhajans(sortedList);
  }, [filteredBhajans, sortType, sortDirection]);

  const handleBhajanClick = (index, bhajan) => {
    fetch(`/Bhajans/${index}.md`)
      .then(response => response.ok ? response.text() : Promise.reject(`Failed to fetch bhajan ${index}.md: ${response.status} ${response.statusText}`))
      .then(data => setSelectedBhajan({ index, bhajan, lyrics: data }))
      .catch(error => {
        console.error(error.message);
        setSelectedBhajan({ index, bhajan, lyrics: "This bhajan is not available." });
      });
  };

  const bhajansToRender = sortedBhajans.length > 0 ? sortedBhajans : filteredBhajans;

  const clearSelectedBhajan = () => {
    setSelectedBhajan(null);
  };

  const openYoutube = () => {
    const youtubeUrl = `https://www.youtube.com/@SiddhaYogaAshram`;
    if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
      window.location.href = `intent:${youtubeUrl}#Intent;package=com.google.android.youtube;end`;
    } else {
      window.open(youtubeUrl, '_blank');
    }
  }

  const HomeButton = ({ clearSelectedBhajan }) => {
    const navigate = useNavigate();

    const handleHomeClick = () => {
      clearSelectedBhajan();
      navigate('/');
    };

    return (
      <button className="button" onClick={handleHomeClick}>
        <FontAwesomeIcon icon={faHome} />
      </button>
    );
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header" style={{ backgroundColor: headerBackground }}>
          <h1>Shri Siddha Gufa Bhajan Library</h1>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search Bhajans..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <HomeButton clearSelectedBhajan={clearSelectedBhajan} />
            <button className='button' onClick={openYoutube}>
              <FontAwesomeIcon icon={faYoutube} />
            </button>
          </div>
        </header>
        <div className="link-list">
          <Routes>
            <Route path="/" element={(
              <table>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Bhajan Name
                      <button className="button-sort" onClick={() => handleSortClick('index')}>
                        <FontAwesomeIcon icon={sortType === 'index' ? (sortDirection === 'asc' ? faSortNumericDown : faSortNumericUp) : faSortNumericDown} />
                      </button>
                      <button className="button-sort" onClick={() => handleSortClick('alphabetical')}>
                        <FontAwesomeIcon icon={sortType === 'alphabetical' ? (sortDirection === 'asc' ? faSortAlphaDown : faSortAlphaUp) : faSortAlphaDown} />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bhajansToRender.map(item => (
                    <tr key={item.index}>
                      <td>#{item.index}</td>
                      <td>
                        <Link
                          to={`/bhajan/${item.index}`}
                          className="link-item"
                          onClick={() => handleBhajanClick(item.index, item.bhajanHin)}
                        >
                          <h2>{item.bhajanHin}</h2>
                          <p>{item.bhajanEng}</p>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )} />
            <Route path="/bhajan/:index" element={<BhajanDetails selectedBhajan={selectedBhajan} clearSelectedBhajan={clearSelectedBhajan} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;