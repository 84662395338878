import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Oops! Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <button onClick={goHome} style={styles.button}>Go to Home</button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '65vh',
        textAlign: 'center',
        paddingTop: '200px',
    },
    heading: {
        fontSize: '24px',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#1C110A',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        marginTop: '20px',
    }
};

export default NotFound;